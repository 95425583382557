<template>
  <el-row gutter="20">
    <el-col
      v-for="item in displayedData"
      :key="item.key"
      :xs="24"
      :sm="12"
      :md="12"
      :lg="6"
      :xl="6"
    >
      <sf-card :titleVisible="false">
        <p class="value">{{item.value}}</p>
        <p class="desc">{{item.desc}}</p>
        <div :class="`rate ${item.increased && 'increased'}`">
          {{item.isTime ? (item.rate+'分钟') : ((item.rate * 100).toFixed(1)+'%')}}
          <span class="rate-desc">
            环比
            <span v-if="item.isTime" class="rate-status">{{item.increased ? '提前' : '推迟'}}</span>
          </span>
        </div>
      </sf-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    userWorkPeriodStats: {},
  },

  computed: {
    displayedData: function(){
      return  [
        {
          id: 'hours',
          value: this.userWorkPeriodStats.validWorkPeriod,
          desc: '上周平均有效工时/天',
          increased: this.userWorkPeriodStats.validWorkPeriodCompare>0,
          rate: this.userWorkPeriodStats.validWorkPeriodCompare,
          isTime: false,
        },
        {
          id: 'percentage',
          value: `${(this.userWorkPeriodStats.validWorkRate * 100).toFixed(1)}%`,
          desc: '上周平均有效工时占比',
          increased: this.userWorkPeriodStats.validWorkRateCompare>0,
          rate: this.userWorkPeriodStats.validWorkRateCompare,
          isTime: false,
        },
        {
          id: 'startTime',
          value: (this.userWorkPeriodStats.avgStartTimeOfLastWeek || '').substring(0,5),
          desc: '上周平均开始工作时间',
          increased: this.userWorkPeriodStats.startTimeDiffCompare<0,
          rate: this.userWorkPeriodStats.startTimeDiffCompare,
          isTime: true,
        },
        {
          id: 'endTime',
          value: (this.userWorkPeriodStats.avgEndTimeOfLastWeek || '').substring(0,5),
          desc: '上周平均结束时间',
          increased: this.userWorkPeriodStats.endTimeDiffCompare<0,
          rate: this.userWorkPeriodStats.endTimeDiffCompare,
          isTime: true,
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.card {
  margin-top: $padding;
  border-radius: 10px;
}
.value {
  margin-top: $padding-xxl;
  margin-bottom: $padding-xl;
  font-size: 66px;
  font-weight: 600;
  color: $color-black;
  text-align: center;
}
.desc {
  margin-bottom: $padding;
  @include title-primary($color-text-regular);
  text-align: center;
}
.rate {
  @include flex-center();
  font-size: 20px;
  color: $color-text-primary;
  line-height: 28px;
  margin-bottom: $padding;
  &:before {
    content: '';
    margin-right: $padding-s;
    margin-top: 10px;
    @include triangle-down(10px, $color-danger);
  }
  .rate-desc {
    margin-left: $padding;
    @include text-primary($color-text-regular);
  }
  .rate-status {
    color: $color-danger;
  }
  &.increased {
    .rate-status {
      color: $color-success;
    }
    &:before {
      content: '';
      margin-top: -10px;
      @include triangle-up(10px, $color-success);
    }
  }
}
</style>
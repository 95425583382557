<template>
  <sf-card
    title="平均有效工作时间"
    subTitle="（小时）"
  >
    <div class="table">
      <el-row class="table-header table-row">
        <div class="category table-cell mutiple">
          <span class="legend-vertical">天</span>
          <span class="legend-horizontal">周</span>
        </div>
        <div
          v-for="item in weekdays"
          :key="item"
          class="legend table-cell">
          {{item}}
        </div>
      </el-row>
      <el-row
        v-for="item in records"
        :key="item.date"
        class="table-row"
      >
        <div class="category table-cell">{{item.date}}</div>
        <div
          v-for="(hours, index) in item.hours"
          :key="index"
          class="value table-cell"
          :style="hours > 0 && {borderColor: 'transparent', cursor: 'pointer'}"
        >
          <div class="background" :style="{ opacity: hours / 10 }"></div>
          <i v-if="hours === null" class="iconfont icon-disable"></i>
          <span v-else>{{hours.toFixed(1)}}</span>
        </div>
      </el-row>
    </div>
  </sf-card>
</template>

<script>
import moment from 'moment';
import { range } from 'lodash';

const dateFormat = 'YYYY-MM-DD';
const numberOfWeeks = 4;
const weekDaysRange = range(7);

export default {
  data() {
    return {
      weekdays: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
    }
  },
  props: {
    averageWorkTime: [],
  },
  computed: {
    records() {
      const mondayOfCurrentWeek = moment().weekday(0);
      return range(numberOfWeeks).reverse().map((weekIndex) => {
        const monday = moment(mondayOfCurrentWeek).subtract(weekIndex, 'weeks');
        const dateList = weekDaysRange.map((weekDay) => (moment(monday).add(weekDay, 'days')).format(dateFormat));
        const hours = dateList.map((date) => (this.averageWorkTime.find(({ workDate }) => workDate === date) || { workPeriod: null}).workPeriod);
        return {
          date: monday.format(dateFormat),
          hours,
        }
      });
    }
  },
}
</script>

<style lang="scss" scoped>
$border-color: #E6E6E6;
$background-color: #F9F9F9;
.table {
  margin: $padding-m;
  background-color: $background-color;
  border-radius: $border-radius;
  border: 1px solid $border-color;
  overflow: hidden;
  .table-cell {
    @include flex-center();
    flex: 1;
    border-top: 1px solid $border-color;
    border-left: 1px solid $border-color;
    &:first-child {
      border-left: none;
    }
  }
  .table-row {
    &:first-child {
      .table-cell {
        border-top: none;
      }
    }
  }
  .category {
    flex: none;
    width: 128px;
    &.mutiple {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-image: linear-gradient(to top right, $background-color, $background-color 49%, $border-color 50%, $border-color 50%, $background-color 50%, $background-color,);
      span {
        padding: 0 $padding-l $padding-s;
        text-align: left;
        &:first-child {
          text-align: right;
          padding: $padding-s $padding-l 0;
        }
      }
    }
  }
  .legend {
    padding: $padding 0;
    @include title-secondary($color-text-primary);
  }
  .value {
    position: relative;
    padding: 48px 0;
    background-color: $color-white;
    font-size: 28px;
    font-weight: 500;
    color: $color-black-green;
    line-height: 22px;
    span, i { position: relative; z-index: 2; }
    .background {
      position: absolute;
      top: -1px;
      left: -1px;
      bottom: -1px;
      right: -1px;
      background-color: #06BAB3;
    }
    .iconfont {
      font-size: 20px;
      color: $color-text-disabled;
    }
    &:after {
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      bottom: -1px;
      right: -1px;
    }
    &:hover {
      &:after {
        background-color: rgba(255,255,255, .25);
      }
    }
  }
}
</style>
<template>
  <sf-card
    title="工作时长健康度"
    subTitle="（每日工时标准为8小时）"
  >
    <div class="chart" ref="chart"></div>
  </sf-card>
</template>

<script>
import * as echarts from 'echarts';
import { reverse } from 'lodash';

let chart = null;

export default {
  data() {
    return {
      pattern: [
        { id: 'less', name: '总工作时长低于标准', color: '#FFB03B' },
        { id: 'more', name: '总工作时长高于标准', color: '#E16133' },
        { id: 'close', name: '总工作时长在标准内', color: '#2DB77F' },
      ],
    }
  },
  props: {
    workPeriodHealthState:[],
  },
  computed: {
    dataDistribution: function(){
      console.log("==============================");
      const arr = this.workPeriodHealthState;
      console.log(arr);
        this.pattern.map((item) => {
          let value = [0,0];
          if(arr[0] != null){
            switch (item.id) {
                case 'less':
                    value=[ arr[0].belowStandard, arr[1].belowStandard];
                  break;
                case 'more':
                    value=[ arr[0].uponStandard, arr[1].uponStandard];
                  break;
                case 'close':
                    value=[ arr[0].withinStandard, arr[1].withinStandard];
                  break;
            }
          }
            return {
              ...item,
                    value
            }
          })
      return this.pattern;
    }
  },
  watch: {
    workPeriodHealthState(newVal) {
      const arr = newVal;
      const pattern = this.pattern.map((item) => {
          let value = [0,0];
          if(arr[0] != null){
            switch (item.id) {
                case 'less':
                    value=[ arr[0].belowStandard, arr[1].belowStandard];
                  break;
                case 'more':
                    value=[ arr[0].uponStandard, arr[1].uponStandard];
                  break;
                case 'close':
                    value=[ arr[0].withinStandard, arr[1].withinStandard];
                  break;
            }
          }
            return {
              ...item,
                    value
            }
          })
      this.renderChart(pattern);
    }
  },
  methods: {
    renderChart(pattern) {
      if (chart && chart.resize) {
        window.removeEventListener('resize', chart.resize);
      }
      chart = echarts.init(this.$refs.chart);

      const lengends = pattern.map(({ name }) => name);

      const series = reverse(pattern).map(({ name, value, color }) => ({
        name: name,
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          formatter: ({ value }) => value*100 > 0 ? `${(value * 100).toFixed()}%` : ``,
          color: '#fff',
          fontSize: 28,
          fontWeight: 500,
        },
        emphasis: {
          focus: 'none',
        },
        data: value,
        color: [color],
        barWidth: '40%',
      }));

      const axisLine = {
        show: true,
        lineStyle: { color: '#DFDFDF' },
      }

      const option = {
        tooltip: {
          trigger: 'series',
        },
        legend: {
          data: lengends,
          itemGap: 30,
          itemWidth: 14,
          itemHeight: 14,
          icon: 'circle',
          textStyle: {
            color: '#373E4E',
            fontSize: 12,
          }
        },
        grid: {
          left: '0',
          right: '0',
          bottom: '2%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: ['上上周\n（前8～14天）', '上周\n（前0～7天）'],
          axisTick: { show: false },
          axisLine,
          axisLabel: {
            formatter: (value) => value.split('\n').map((item, index) => index > 0 ? `{secondary|${item}}` : `{primary|${item}}`).join('\n'),
            rich: {
              primary: {
                lineHeight: 38,
                fontWeight: 500,
                color: '#373E4E',
                fontSize: 18,
              },
              secondary: {
                color: '#999',
                fontSize: 12,
              }
            }
          }
        },
        yAxis: {
          type: 'value',
          min: 0,
          max: 1,
          interval: 0.5,
          axisLabel: {
            formatter: (value) => value === 0.5 ? '健康数' : '',
            color: '#373E4E',
            fontSize: 16,
          },
          axisLine,
          splitLine: { show: false },
        },
        series,
      }

      chart.setOption(option);
      window.addEventListener('resize', chart.resize);
    }
  },
}
</script>

<style lang="scss" scoped>
.chart {
  height: 100%;
  min-height: 520px;
}
</style>
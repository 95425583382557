<template>
  <el-row :gutter="20" class="row active-user_overview">
    <el-col :span="24">
      <DataDisplay :userWorkPeriodStats="userWorkPeriodStats"/>
    </el-col>
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="12"
      :xl="12"
    >
      <Average :averageWorkTime="averageWorkTime"/>
    </el-col>
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="12"
      :xl="12"
    >
      <Healthy :workPeriodHealthState="workPeriodHealthState"/>
    </el-col>
  </el-row>
</template>

<script>
import { get } from 'lodash';
import DataDisplay from './components/dataDisplay';
import Average from './components/average';
import Healthy from './components/healthy';
import * as api from '@constants/apis';
let loading = null;

export default {
  components: {
    DataDisplay,
    Average,
    Healthy,
  },
  mounted() {
    this.init()
  },
  data(){
    return {
      userWorkPeriodStats: {},
      averageWorkTime: [],
      workPeriodHealthState: {}
    }
  },
  methods: {
    async init() {
      try {
        loading = this.$loading({
          lock: true,
          spinner: 'el-icon-loading',
          customClass: 'sf-loading',
          target: document.querySelector('.active-user_overview'),
        });
        const res = await this.$httpRequest.get(api.HOME_ONLINE_USER_DETAIL);
        this.workPeriodHealthState = res.data.workPeriodHealthState;
        this.userWorkPeriodStats = res.data.userWorkPeriodStats;
        this.averageWorkTime = get(res, ['data', 'avgTotalWorkPeriod'], []);
      } catch (err) {
        console.log(err);
      } finally {
        loading.close();
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.row {
  margin-top: -$padding;
  > .el-col {
    margin-top: $padding;
  }
}
.card {
  height: 100%;
}
</style>